<template>
  <b-form-group
    label="Özet Metin"
    label-for="summary"
  >
    <b-form-textarea
      id="summary"
      v-model="dataItem.summary"
      placeholder="Özet Metin"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'Summary',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    dataItem() {
      return this.$store.getters['blog/dataItem']
    },
  },
}
</script>
