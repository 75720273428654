<template>
  <b-form-group
    label="Kategori"
    label-for="id_blog_categories"
  >
    <validation-provider
      #default="{ errors }"
      name="Kategori"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_blog_categories"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Kategori"
      >
        <template v-slot:option="option">
          <div>
            {{ option.title }}
          </div>
          <div>
            <small class="text-warning">{{ option.main_category }}</small>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'BlogCategory',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['blogCategories/dataList']
    },
    dataItem() {
      return this.$store.getters['blog/dataItem']
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('blogCategories/getDataList', {
        select: [
          'blog_categories.id AS id',
          'blog_categories.title AS title',
          'bc.title AS main_category',
        ],
        where: {
          'blog_categories.id_blog_categories !=': null,
        },
      })
    },
  },
}
</script>
