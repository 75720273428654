<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <blog-category />
        </b-col>
        <b-col
          cols="12"
        >
          <title-text />
        </b-col>
        <b-col cols="12">
          <summary-text />
        </b-col>
        <b-col cols="12">
          <content-text />
        </b-col>
      </b-row>
    </b-card>
    <image-card />
    <photo-gallery />
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'

import BlogCategory from '@/views/Admin/Blog/elements/BlogCategory.vue'
import TitleText from '@/views/Admin/Blog/elements/Title.vue'
import SummaryText from '@/views/Admin/Blog/elements/Summary.vue'
import ContentText from '@/views/Admin/Blog/elements/Content.vue'
import ImageCard from '@/views/Admin/Blog/elements/Image.vue'
import PhotoGallery from '@/views/Admin/Blog/elements/PhotoGallery.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BlogCategory,
    TitleText,
    SummaryText,
    ContentText,
    ImageCard,
    PhotoGallery,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['blog/dataItem']
    },
  },
}
</script>
